import type { Crew, Personnel } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

import { CrewPayload } from '@stores-models/crew-payload';

/**
 * Add Personnel
 *
 * @param payload Personnel object
 */
export class AddPersonnel {
  static readonly type = '[Personnel] Add';

  constructor(public payload: Personnel) {}
}

/**
 * Get all Personnels
 *
 * @param filter OData filter
 */
export class GetPersonnels {
  static readonly type = '[Personnel] Get';

  constructor(public filter?: string) {}
}

/**
 * Get crews
 */
export class GetCrews {
  static readonly type = '[Personnel] Get Crews';

  constructor() {}
}

/**
 * Add crew
 */
export class AddCrew {
  static readonly type = '[Personnel] Add Crew';
  constructor(public payload: CrewPayload) {}
}

/**
 * Update crew
 */
export class UpdateCrew {
  static readonly type = '[Personnel] Update Crew';
  constructor(public code: string, public payload: CrewPayload) {}
}

/**
 * Get crew schedules
 */
export class GetCrewSchedules {
  static readonly type = '[Personnel] Get Crew Schedules';

  constructor() {}
}

/**
 * Update Personnel Line, this method will dynamically update the cache Personnel list and selected Personnel.
 *
 * @param no Personnel no
 * @param patch JSON patch object used to update the Personnel. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdatePersonnel {
  static readonly type = '[Personnel] Update';

  constructor(
    public no: string,
    public patch: Operation[],
    public selectedItem?: Personnel
  ) {}
}

/**
 * Delete Personnel, this method will dynamically update the cache Personnel list and set selected Personnel to null.
 *
 * @param no Personnel no
 */
export class DeletePersonnel {
  static readonly type = '[Personnel] Delete';

  constructor(public no: string) {}
}

/**
 * Delete Personnel, this method will dynamically update the cache Personnel list and set selected Personnel to null.
 *
 * @param no Personnel no
 */
export class DeleteCrew {
  static readonly type = '[Crew] Delete';

  constructor(public code: string) {}
}

/**
 * Set selected Crew
 *
 * @param payload Crew full object. Summary is not supported.
 */
export class SetSelectedCrew {
  static readonly type = '[Personnel] Set Crew';

  constructor(public payload: Crew | null) {}
}
