/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import type { Crew, CrewSchedule, Personnel } from '@tag/graphql';
import { CrewMember } from '@api/types';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddNotification } from '@stores-actions/notification.action';
import {
  AddCrew,
  AddPersonnel,
  DeleteCrew,
  DeletePersonnel,
  GetCrewSchedules,
  GetCrews,
  GetPersonnels,
  UpdateCrew,
  UpdatePersonnel,
} from '@stores-actions/personnel.action';
import { PersonnelStoreService } from '@stores-services/personnel-store.service';

import { AuthState } from './authentication.state';

export class PersonnelStateModel {
  personnels: Personnel[] = [];
  selectedPersonnel!: Personnel | null;
  crews: Crew[] = [];
  selectedCrew: Crew | null = null;
  crewSchedules: CrewSchedule[] = [];
  crewScheduleInitialised = false;
}

/**
 * Personnel Lines metadata and action mappings.
 */
@State<PersonnelStateModel>({
  name: 'personnel',
  defaults: {
    personnels: [],
    selectedPersonnel: null,
    crews: [],
    selectedCrew: null,
    crewSchedules: [],
    crewScheduleInitialised: false,
  },
})
@Injectable()
export class PersonnelState {
  constructor(
    private personnelStoreService: PersonnelStoreService,
    private store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo
  ) {}

  @Selector()
  static getPersonnels(state: PersonnelStateModel): Personnel[] {
    return state.personnels;
  }

  @Selector()
  static getSelectedPersonnel(state: PersonnelStateModel): Personnel | null {
    return state.selectedPersonnel;
  }

  @Selector()
  static getCrews(state: PersonnelStateModel): Crew[] {
    return state.crews;
  }

  @Selector()
  static getCrewSchedules(state: PersonnelStateModel): CrewSchedule[] {
    return state.crewSchedules;
  }

  @Selector()
  static getSelectedCrew(state: PersonnelStateModel): Crew | null {
    return state.selectedCrew;
  }

  @Action(GetPersonnels, { cancelUncompleted: true })
  getPersonnels(
    { getState, setState }: StateContext<PersonnelStateModel>,
    { filter }: GetPersonnels
  ): Observable<Personnel[]> {
    return this.personnelStoreService.fetchPersonnels(filter).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          personnels: result,
        });
      })
    );
  }

  @Action(GetCrews, { cancelUncompleted: true })
  getCrews({
    getState,
    setState,
  }: StateContext<PersonnelStateModel>): Observable<Crew[]> {
    return this.personnelStoreService.getCrews().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          crews: result,
        });
      })
    );
  }

  @Action(GetCrewSchedules, { cancelUncompleted: true })
  getCrewSchedules({
    getState,
    setState,
  }: StateContext<PersonnelStateModel>): Observable<CrewSchedule[]> {
    let state = getState();

    if (state.crewScheduleInitialised) return of(state.crewSchedules);
    return this.personnelStoreService.getCrewSchedules().pipe(
      tap((result) => {
        state = getState();
        setState({
          ...state,
          crewSchedules: result,
          crewScheduleInitialised: true,
        });
      })
    );
  }

  @Action(AddPersonnel)
  addPersonnel(
    { getState, patchState }: StateContext<PersonnelStateModel>,
    { payload }: AddPersonnel
  ): Observable<Personnel> {
    return this.personnelStoreService.addPersonnel(payload).pipe(
      tap((result) => {
        const state = getState();
        patchState({
          personnels: [...state.personnels, result],
          selectedPersonnel: result,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.personnelNoWasCreatedSuccessfullyKeyParam',
          {
            id: result.no,
          }
        );
        const title = this.translocoService.translate('createKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'personnels',
        });
      })
    );
  }

  @Action(AddCrew)
  addCrew(
    { getState, setState }: StateContext<PersonnelStateModel>,
    { payload }: AddCrew
  ): Observable<{ m: CrewMember[]; c: Crew }> {
    return this.personnelStoreService.addCrew(payload).pipe(
      tap(({ m, c }) => {
        const state = getState();

        const cSchedule: any[] = m.map((member) => ({
          ...member,
          crew_Code: c.code,
          endDatetime: null,
          startDatetime: null,
          entryNo: 0,
          personnelType: '',
        }));

        setState({
          ...state,
          crews: [...state.crews, c],
          crewSchedules: [...state.crewSchedules, ...cSchedule],
        });
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.crewWasCreatedSuccessfullyKeyParam',
          {
            id: c.code,
          }
        );
        const title = this.translocoService.translate('createKey');
        this.store.dispatch(new AddNotification(message, 'success', title));
      })
    );
  }

  @Action(UpdatePersonnel)
  updatePersonnel(
    { getState, setState }: StateContext<PersonnelStateModel>,
    { patch, no, selectedItem }: UpdatePersonnel
  ): Observable<Personnel> {
    return this.personnelStoreService.updatePersonnel(no, patch).pipe(
      tap((result) => {
        const state = getState();
        const personnelLineList = [...state.personnels];
        const personnelLineIndex = personnelLineList.findIndex(
          (item) => item.no === no
        );
        personnelLineList[personnelLineIndex] = result;
        setState({
          ...state,
          personnels: personnelLineList,
          selectedPersonnel: selectedItem !== undefined ? selectedItem : result,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.personnelNoWasUpdatedSuccessfullyKeyParam',
          { id: no }
        );
        const title = this.translocoService.translate('updatedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'personnels',
        });
      })
    );
  }

  @Action(UpdateCrew)
  updateCrew(
    { getState, setState }: StateContext<PersonnelStateModel>,
    { code, payload }: UpdateCrew
  ): Observable<{ m: CrewMember[]; c: Crew }> {
    return this.personnelStoreService.updateCrew(code, payload).pipe(
      tap((result) => {
        const state = getState();
        const crewList = [...state.crews];
        const crewScheduleList = [...state.crewSchedules].filter(
          (item) => item.crewCode !== code
        );
        const i = crewList.findIndex((item) => item.code === code);
        crewList[i] = result.c;

        const cSchedule: any[] = result.m.map((member) => ({
          ...member,
          crew_Code: result.c.code,
        }));

        setState({
          ...state,
          crews: crewList,
          crewSchedules: [...crewScheduleList, ...cSchedule],
          selectedCrew: result.c,
        });
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.crewWasUpdatedSuccessfullyKeyParam',
          {
            id: code,
          }
        );
        const title = this.translocoService.translate('updatedKey');
        this.store.dispatch(new AddNotification(message, 'success', title));
      })
    );
  }

  @Action(DeletePersonnel)
  deletePersonnel(
    { getState, setState }: StateContext<PersonnelStateModel>,
    { no }: DeletePersonnel
  ): Observable<Personnel> {
    return this.personnelStoreService.deletePersonnel(no).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.personnels.filter((item) => item.no !== no);
        setState({
          ...state,
          personnels: filteredArray,
          selectedPersonnel: null,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.personnelNoWasDeletedSuccessfullyKeyParam',
          { id: no }
        );
        const title = this.translocoService.translate('deletedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'personnels',
        });
      })
    );
  }

  @Action(DeleteCrew)
  deleteCrew(
    { getState, setState }: StateContext<PersonnelStateModel>,
    { code }: DeleteCrew
  ): Observable<Crew> {
    return this.personnelStoreService.deleteCrew(code).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.crews.filter((item) => item.code !== code);
        const filterSchedule = state.crewSchedules.filter(
          (item) => item.crewCode !== code
        );
        setState({
          ...state,
          crews: filteredArray,
          crewSchedules: filterSchedule,
          selectedCrew: null,
        });
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.crewWasDeletedSuccessfullyKeyParam',
          {
            id: code,
          }
        );
        const title = this.translocoService.translate('deletedKey');
        this.store.dispatch(new AddNotification(message, 'success', title));
      })
    );
  }
}
