/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import type { Crew, Personnel } from '@tag/graphql';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PersonnelsGQL } from '@shared/apollo/queries/personnel';
import { CrewsGQL } from '@shared/apollo/queries/time-and-attendance/crew';
import { CrewScheduleGQL } from '@shared/apollo/queries/time-and-attendance/crew-schedule';
import { CrewPayload } from '@stores-models/crew-payload';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class PersonnelStoreService {
  constructor(
    private store: Store,
    private personnelsGQL: PersonnelsGQL,
    private crewsGQL: CrewsGQL,
    private crewScheduleGQL: CrewScheduleGQL
  ) {}

  /**
   * Fetchs personnel
   *
   * @param filter OData filter
   *
   * @returns personnel
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPersonnels(filter?: string): Observable<Personnel[]> {
    return this.personnelsGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data?.personnels?.items || []));
  }

  /**
   * Deletes personnel
   *
   * @param no
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deletePersonnel(no: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds personnel
   *
   * @param payload
   *
   * @returns personnel
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addPersonnel(payload: Personnel): Observable<Personnel> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds crew
   *
   * @param payload
   * @param company
   * @returns crew
   */
  addCrew(payload: CrewPayload): Observable<{ m: any[]; c: Crew }> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates personnel
   *
   * @param no
   * @param patch
   *
   * @returns personnel
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updatePersonnel(no: string, patch: Operation[]): Observable<Personnel> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates crew
   *
   * @param code
   * @param payload
   *
   * @returns personnel
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateCrew(
    code: string,
    payload: CrewPayload
  ): Observable<{ m: any[]; c: Crew }> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get Crews
   *
   *
   * @returns personnel
   * @deprecated This is an internal implementation method, do not use directly.
   */
  getCrews() {
    return this.crewsGQL
      .watch({ filter: null })
      .valueChanges.pipe(map((result) => result.data.crews.items));
  }

  /**
   * Deletes crew
   *
   * @param code
   * @param company
   * @returns crew
   */
  deleteCrew(code: string): any {
    throw new Error('Method not implemented.');
  }

  /**
   * Gets crew schedules
   *
   * @param company
   * @returns crew schedule
   */
  getCrewSchedules() {
    return this.crewScheduleGQL
      .watch({ filter: null })
      .valueChanges.pipe(map((result) => result.data.crewSchedule.items));
  }

  /**
   * Updates crew lines
   *
   * @param payload
   * @param result
   * @param company
   * @param deleteOnFailure
   * @returns crew lines
   */
  private updateCrewLines(
    payload: CrewPayload,
    result: Crew,

    deleteOnFailure = false
  ) {
    throw new Error('Method not implemented.');
  }
}
